import { formatarData, obterPrimeiroUltimoNome, preparaEndereco } from "./Formatar";

export const setupIframe = (props, cliente, installments, totalCarrinho, response) => {
    const script = document.createElement('script');

    script.id = 'getnet-loader-script';
    script.src = `${process.env.REACT_APP_GETNET_URL}loader.js`;
    script.async = true;
        
    script.setAttribute('data-getnet-sellerid', response.seller_id);
    script.setAttribute('data-getnet-token', `Bearer ${response.access_token}`);

    if (props.carrinho) {
        var carrinho = props.carrinho;
        var endereco = carrinho.entrega.endereco;
        var carrinho_item_peso = carrinho.carrinho_item_peso;
        var carrinho_item_quantidade = carrinho.carrinho_item_quantidade;
     } else {
        var carrinho = props.pedido;
        var endereco = carrinho.endereco_entrega;
        var carrinho_item_quantidade = carrinho.pedido_item;
     }

    script.setAttribute('data-getnet-payment-methods-disabled', `[${response.method_payments_disabled}]`);
    script.setAttribute('data-getnet-amount', totalCarrinho);
    script.setAttribute('data-getnet-customerid', cliente.cliente.id);
    script.setAttribute('data-getnet-orderid', props.pedido_id);
    script.setAttribute('data-getnet-button-class', 'pay-button-getnet');
    script.setAttribute('data-getnet-installments', installments);

    const nome = obterPrimeiroUltimoNome(cliente.cliente.nome);

    // script.setAttribute('data-getnet-boleto-expiration-date', '12/10/2028');

    script.setAttribute('data-getnet-customer-first-name', nome.primeiroNome);
    script.setAttribute('data-getnet-customer-last-name', nome.ultimoNome);
    script.setAttribute('data-getnet-customer-document-type', 'CPF');
    script.setAttribute('data-getnet-customer-document-number', carrinho.documento);
    script.setAttribute('data-getnet-customer-email', cliente.usuario.email);
    script.setAttribute('data-getnet-customer-mobile-number', cliente.cliente.telefone);
    script.setAttribute('data-getnet-customer-phone-number', cliente.cliente.telefone);
    script.setAttribute('data-getnet-customer-address-street', preparaEndereco(endereco.logradouro, endereco.endereco));
    script.setAttribute('data-getnet-customer-address-street-number', endereco.numero);  
    script.setAttribute('data-getnet-customer-address-complementary', endereco.complemento);    
    script.setAttribute('data-getnet-customer-address-neighborhood', endereco.bairro);
    script.setAttribute('data-getnet-customer-address-city', endereco.cidade);
    script.setAttribute('data-getnet-customer-address-state', endereco.uf);
    script.setAttribute('data-getnet-customer-address-zipcode', endereco.cep);
    script.setAttribute('data-getnet-customer-country', 'BR');
    script.setAttribute('data-getnet-shipping-address', JSON.stringify([{
        first_name: nome.primeiroNome,
        name: cliente.cliente.nome,
        email: cliente.usuario.email,
        phone_number: cliente.cliente.telefone,
        last_usage: formatarData(new Date()),
        shipping_amount: 0,
        address: {
            street: preparaEndereco(endereco.logradouro, endereco.endereco),
            complement: endereco.complemento,
            number: endereco.numero,
            district: endereco.bairro,
            city: endereco.cidade,
            state: endereco.uf,
            country: 'BR',
            postal_code: endereco.cep
        }
    }]));
    
    let items = [];
    if (carrinho_item_peso) {
        carrinho_item_peso.map(item => items.push({
            name: item.descricao,
            description: item.descricao,
            value: item.liquido,
            quantity: item.quantidade,
            sku: item.id
        }));
    }

    if (carrinho_item_quantidade) {
        carrinho_item_quantidade.map(item => items.push({      
            name: item.descricao,
            description: item.descricao,
            value: item.liquido,
            quantity: item.quantidade,
            sku: item.id      
        }));
    }

    script.setAttribute('data-getnet-items', items);    
    script.setAttribute('data-getnet-soft-descriptor', process.env.REACT_APP_GETNET_SOFT_DESCRIPTOR);
    script.setAttribute('data-getnet-card-caixa', 'false');

    document.body.appendChild(script);    
}

export const setupIframeListener = () => {
  const getnetIfrm = document.getElementById('getnet-checkout');

  if (getnetIfrm) {
    getnetIfrm.addEventListener('load', (ev) => {      
      const eventMethod = (window.addEventListener ? 'addEventListener' : 'attachEvent');
      const eventer = window[eventMethod];
      const messageEvent = (eventMethod === 'attachEvent') ? 'onmessage' : 'message';
    
      eventer(messageEvent, function iframeMessage(e) {
        const data = e.data || '';
        let payload;
        
        switch (data.status || data) {        
          case 'success':
            payload = {
              status: "success",
              message: "Transação realizada."
            }
            break;        
          case 'close':
            payload = {
              status:  "close",
              message: "Checkout fechado."
            }
            break;          
          case 'pending': 
            payload = {
              status:  "pending",
              message: "Boleto registrado e pendente de pagamento",
              detail: data.detail
            }            
            break;             
          case 'error': 
            payload = {
              status:  "error",
              message: data.detail.message              
            }            
            break;       
          default:
            break;
        }

        const customEvent = new CustomEvent('iframeMessage', { detail: payload });
        window.dispatchEvent(customEvent);
      }, false);
    });
  }
};

export const uninstallIframeListener = () => {
    const getnetIfrm = document.getElementById('getnet-checkout');
    
    if (getnetIfrm) {
        getnetIfrm.remove();    
    }
}
