import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import AbstractComponent from "../../../AbstractComponent";
import ModalTitle from "../../../layout/modal/ModalTitle";
import ButtonEscolher from "../../../layout/button/ButtonEscolher";
import PagarNaEntrega from "./PagarNaEntrega";
import PagoNoSite from "./PagoNoSite";

import OpcaoEntregaTipoEnum from "../../../../helper/enumerador/OpcaoEntregaTipoEnum";

import "../../../layout/modal/Modal.css";

class OpcaoPagamentoModal extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      passo: 1,
    };
  }

  getEntrega() {
    if (
      this.props.opcaoEntrega === null ||
      this.props.opcaoEntrega === undefined ||
      this.props.opcaoEntrega.length === 0 ||
      this.props.entrega === null ||
      this.props.entrega === undefined
    ) {
      return undefined;
    }

    if (        
        this.props.opcaoEntrega.length === 1 &&
        this.state.passo === 1
    ) {
    this.setState({ passo: 2 })
        return this.props.entrega.tipo;
    }

    let tipo = "";

    this.props.opcaoEntrega.map((item) => {
      if (item.id === this.props.entrega.id) {
        tipo = item.tipo;
        return;
      }
    });

    return tipo;
  }

  getTextoComplementar() {
    const tipo = this.getEntrega();    

    return OpcaoEntregaTipoEnum.get(tipo).pagarNa();
  }

  showButtonPagarNaEntega() {
    if (this.props.modalidadePagamento.pagar_na_entrega.length === 0) {
      return;
    }

    if (this.props.modalidadePagamento.pago_no_site.length === 0) {
      this.setState({ passo: 2 });

      return;
    }

    return (
      <ButtonEscolher
        type="button"
        className="pagamento"
        classNameEscolher="escolher"
        texto={`Pagar na ${this.getTextoComplementar()}`}
        textoSelecionar="Escolher"
        imagem={"pagar-na-entrega.png"}
        onClick={() => this.setState({ passo: 2 })}
      />
    );
  }

  showButtonPagarNoSiteModalidadePagamento() {
    if (this.props.modalidadePagamento.pago_no_site.length > 1) {
      return this.setState({ passo: 3 });
    }

    const modalidadePagamento = this.props.modalidadePagamento.pago_no_site[0].dados[0];
    
    return this.onUpdatePagamento(
      modalidadePagamento.id,
      modalidadePagamento.descricao.toUpperCase(),
      modalidadePagamento.troco,
      "PAGAR AGORA",
      modalidadePagamento.gateway,
      modalidadePagamento.permite_parcelamento
    );        
  }

  showButtonPagoNoSite() {
    if (this.props.modalidadePagamento.pago_no_site.length === 0) {
      return;
    }

    if (this.props.modalidadePagamento.pagar_na_entrega.length === 0) {
      this.setState({ passo: 3 });

      return;
    }

    return (
      <ButtonEscolher
        type="button"
        className="pagamento"
        classNameEscolher="escolher"
        pagarNaEntregaExists={
          this.props.modalidadePagamento.pagar_na_entrega.length > 0
        }
        texto="Pagar Agora"
        textoSelecionar="Escolher"
        imagem={"pago-agora.png"}
        onClick={() => this.showButtonPagarNoSiteModalidadePagamento()}
      />
    );
  }

  opcaoPagamento() {
    if (this.state.passo > 1) {
      return;
    }

    return (
      <Fragment>
        {this.showButtonPagarNaEntega()}

        {this.showButtonPagoNoSite()}
      </Fragment>
    );
  }

  fechar() {
    if (
      this.state.passo === 1 ||
      this.props.modalidadePagamento.pago_no_site.length === 0 ||
      this.props.modalidadePagamento.pagar_na_entrega.length === 0 ||
      (this.state.passo === 2 && this.props.opcaoEntrega.length === 1)
    ) {
      this.props.fechar();
    }

    this.setState({ passo: 1 });
  }

  onUpdatePagamento(
    id,
    descricao,
    troco,
    opcaoPagamento,
    gatewayPagamento,
    permite_parcelamento
  ) {
    this.setState({ passo: 1 });
    this.props.onUpdatePagamento(
      id,
      descricao,
      troco,
      opcaoPagamento,
      gatewayPagamento,
      permite_parcelamento
    );
  }

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.show}
          className={this.props.className}
          style={{ zIndex: 9999 }}
          onHide={() => this.props.fechar()}
        >
          <ModalTitle
            icon={
              this.state.passo === 1 ||
              this.props.modalidadePagamento.pago_no_site.length === 0 ||
              this.props.modalidadePagamento.pagar_na_entrega.length === 0 || 
              (this.state.passo === 2 && this.props.opcaoEntrega.length === 1)
                ? "down"
                : "left"
            }
            escondeLupa={false}
            header={
              this.state.passo === 1
                ? "OPÇÃO DE PAGAMENTO"
                : "MODALIDADE DE PAGAMENTO"
            }
            subheader={
              this.state.passo === 1
                ? ""
                : this.state.passo === 2
                ? "PAGAR NA ENTREGA"
                : "PAGAR AGORA"
            }
            fechar={() => this.fechar()}
          />

          <Modal.Body>
            <div className="container-overflow fullheight">
              {this.opcaoPagamento()}
              
              <div id="pagarNaEntrega">
                <PagarNaEntrega
                  show={this.state.passo === 2}
                  modalidadePagamento={
                    this.props.modalidadePagamento.pagar_na_entrega
                  }
                  totalPedido={this.props.totalPedido}
                  onUpdatePagamento={(
                    id,
                    descricao,
                    troco,
                    gatewayPagamento,
                    permite_parcelamento
                  ) =>
                    this.onUpdatePagamento(
                      id,
                      descricao,
                      troco,
                      "PAGAR NA ENTREGA",
                      gatewayPagamento,
                      permite_parcelamento
                    )
                  }
                />
              </div>

              <div id="pagoNoSite">
                <PagoNoSite
                  show={this.state.passo === 3}
                  modalidadePagamento={
                    this.props.modalidadePagamento.pago_no_site
                  }
                  totalPedido={this.props.totalPedido}
                  onUpdatePagamento={(
                    id,
                    descricao,
                    troco,
                    gatewayPagamento,
                    permite_parcelamento
                  ) =>
                    this.onUpdatePagamento(
                      id,
                      descricao,
                      troco,
                      "PAGAR AGORA",
                      gatewayPagamento,
                      permite_parcelamento
                    )
                  }
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

OpcaoPagamentoModal.defaultProps = {
  header: "",
  update: false,
  item: [],
  id: 0,
};

export default OpcaoPagamentoModal;
