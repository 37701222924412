import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import CarrinhoItem from '../carrinho-desktop/CarrinhoItem';
import PreLoadCarrinho from '../../layout/preload/PreLoadCarrinho';
import CarrinhoEmpty from '../carrinho-desktop/CarrinhoEmpty';

import AutenticarService from '../../../service/AutenticarService';

import ShoppingBagEnum from '../../../helper/enumerador/ShoppingBagEnum';
import TipoEnderecoEnum from '../../../helper/enumerador/TipoEnderecoEnum';

import {formataEndereco} from '../../../helper/Formatar';
class CarrinhoContent extends AbstractComponent {   
    constructor(props) {
        super(props);          
            
        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());
    }             

    showPoI() {
        if(this.props.cliente.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
            return ;

        return (
            <Fragment>
                <div className="col-md-10 
                                col-sm-10                                                
                                col-lg-10                                                
                                col-xs-10">
                    <p className="delivery-info__street-name">
                        <label>
                            Ponto de Interesse:
                        </label>
                        
                        &nbsp;

                        { this.props.cliente.ponto_de_interesse } 
                    </p>

                    <p className="delivery-info__complement">
                        { this.props.cliente.ponto_de_interesse_descricao }
                    </p>
                </div>                
            </Fragment>
        )
    } 

    showEndereco() {
        if(this.props.cliente.tipo_endereco === TipoEnderecoEnum.POI.name) 
            return ;

        return (
            <Fragment>
                <div className="col-md-10 
                                col-sm-10                                                
                                col-lg-10                                                
                                col-xs-10">
                    <p className="delivery-info__street-name">
                        {formataEndereco(this.props.cliente.logradouro, this.props.cliente.endereco) + ', ' + this.props.cliente.numero}
                    </p>

                    <p className="delivery-info__complement">                                        
                        {this.props.cliente.bairro}
                    </p>
                </div>
            </Fragment>
        )
    }

    render() {
        return(       
            <Fragment>                
                {this.props.show &&
                    <Fragment>                        
                        <header id="carrinhoHeader" className="nav-header nav-header--tertiary nav-header--with-padding">
                            <button 
                                type="button" 
                                className="btn-icon btn-icon--primary 
                                           btn-icon--size-m 
                                           btn-icon--transparent 
                                           nav-header__left-button"
                                onClick={() => this.props.onClick()}>
                                
                                <span className="icon-marmita icon-marmita--bag">
                                    <i className="header-icon-font fa fa-arrow-down" style={{fontSize: '16px'}}/>                                    
                                </span>
                            </button>
                            
                            <div className="nav-header__title">
                            </div>
                        </header>
                        
                        <div id="bodyCarrinho" className={"context-card__content " + (this.props.preload ? 'area-cart' : '')}>
                            <div className="checkout__container-scroll">
                                {
                                    !this.props.preload &&                                
                                    <div className="checkout__delivery">
                                        {this.props.cliente.id > 0 &&
                                            <div className="delivery-info">                                        
                                                <div className="col-md-12                                 
                                                                col-sm-12                                                
                                                                col-lg-12                                                
                                                                col-xs-12">                                            
                                                    <div 
                                                        className="col-md-1                                 
                                                                col-sm-1                                                
                                                                col-lg-1                                                
                                                                col-xs-1"
                                                        style={{paddingTop: '5px'}}
                                                    >
                                                        <span className="icon-marmita icon-marmita--home">
                                                            <i className="icon-marmita pe-7s-home" />
                                                        </span>
                                                    </div>

                                                    { this.showEndereco() }                                         

                                                    { this.showPoI() }                                                    
                                                </div>                                                
                                            </div>
                                        }
                                    </div>
                                }
                                
                                <div className="checkout__order">
                                    <div className="area-cart checkout checkout--with-padding">
                                        <PreLoadCarrinho
                                            exibir={this.props.preload || this.props.tipoCalculoFrete === ''}                                            
                                        />

                                        {

                                            this.props.tipoCalculoFrete !== '' &&
                                                <>
                                                    <CarrinhoItem
                                                        visible={!this.props.preload && (this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length)> 0}
                                                        
                                                        cliente={this.props.cliente}                                            
                                                        empresa={this.props.empresa}
                                                        pagamento={this.props.pagamento}
                                                        modalidadePagamento={this.props.modalidadePagamento}
                                                        carrinho={this.props.carrinho}                                            
                                                        opcaoEntrega={this.props.opcaoEntrega}
                                                        tipoCalculoFrete={this.props.tipoCalculoFrete} 
                                                        usaCheckout={this.props.usaCheckout}                                                                                                                                            
                                                                                                                                                
                                                        onUpdateCart={(carrinho, item, indiceCarrinhoItem, quantidade) => this.props.onUpdateCart(carrinho, item, indiceCarrinhoItem, quantidade)}
                                                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                                                        onUpdatePagamento={(
                                                          id,
                                                          descricao,
                                                          troco,
                                                          opcaoPagamento,
                                                          gatewayPagamento,
                                                          permite_parcelamento
                                                        ) => 
                                                          this.props.onUpdatePagamento(
                                                            id,
                                                            descricao,
                                                            troco,
                                                            opcaoPagamento,
                                                            gatewayPagamento,
                                                            permite_parcelamento
                                                          )
                                                        }
                                                        refreshCart={() => this.props.refreshCart()}
                                                        
                                                        route={(item) => this.props.route(item)}
                                                    />   

                                                    <CarrinhoEmpty                                
                                                        visible={!this.props.preload && (this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length) === 0}
                                                    /> 
                                                </>
                                        }                                                                            
                                    </div>
                                </div>                                                        
                            </div>
                        </div>            
                    
                        <div className="context-card__overlay">
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default CarrinhoContent;
