import { retiraAcentos } from '../Formatar';

var gatewayPagamentoEnum = {
    CIELO: {
        enumName: 'Cielo',
        name: function() {
            return "Cielo";
        },
    },
    GETNET: {
        enumName: 'Getnet',
        name: function() {
            return "Getnet";
        },
    },
    NAO_SE_APLICA: {
        enumName: 'Nao se aplica',
        name: function() {
            return "Não se aplica";
        },
    },
    get(value) {        
        if (value === null || value === undefined || value === "undefined")
            return gatewayPagamentoEnum.CIELO;

        value = retiraAcentos(value);
        value = value.replaceAll(' - ', ' ');
        value = value.replaceAll(' ', '_');

        var array = Object.values(gatewayPagamentoEnum);
        var gatewayPagamentoFound = {};

        array.forEach(element => {            
            if(element.enumName === value) {
              gatewayPagamentoFound = element;      
                return ;          
            }            
        });   
        
        return gatewayPagamentoFound;
    }
}

export default gatewayPagamentoEnum;
