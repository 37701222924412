import React from 'react';
import { Modal } from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';
import ModalTitle from '../layout/modal/ModalTitle';
import Table from '../layout/table/Table';
import PedidoHistorico from './PedidoHistorico';

import StatusPedidoEnum from '../../helper/enumerador/StatusPedidoEnum';

import { moeda, formataEndereco, formatarData } from '../../helper/Formatar';
import Button from '../layout/button/Button';

class PedidoDetalheModal extends AbstractComponent {
    getTaxaEntrega() {
        if(this.props.pedido.totalizador.taxa_entrega === 0.00)
            return 'Grátis';

        return moeda(this.props.pedido.totalizador.taxa_entrega);
    }

    showSummary(texto, valor) {
        return (
            <div 
                className="col-md-12                                 
                            col-sm-12                                               
                            col-lg-12                                               
                            col-xs-12"
                style={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    marginTop: '10px'
                }}
            >
                <div 
                    className="col-md-6                                 
                                col-sm-6                                               
                                col-lg-6                                               
                                col-xs-6
                                text-font-16px"
                    style={{
                        paddingLeft: '0px'                                    
                    }}
                >
                    <b>{texto}</b>
                </div>
                
                <div 
                    className="col-md-6                                 
                                col-sm-6                                               
                                col-lg-6                                               
                                col-xs-6
                                text-right
                                text-font-16px"
                    style={{
                        paddingRight: '0px'
                    }}
                >                                
                    {valor}
                </div>
            </div>
        )
    }

    render() {
        return (
            <Modal 
                show={this.props.show}
                className={'modal-detail'}
                onHide={() => this.props.fechar()}
                style={{ zIndex: 9999 }}
            >
                <ModalTitle
                    header={"Detalhamento do Pedido"}
                    fechar={() => this.props.fechar()}
                >
                    <p className="header-modal-texto-1">
                        <b>Pedido:</b>
                        &nbsp;
                        #{this.props.pedido.id}
                    </p>
                    
                    <div 
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                        <div className="header-modal-texto-2">
                            <b>Data:</b>                                
                            &nbsp;
                            {formatarData(this.props.pedido.data)}
                        </div>

                        <div className="header-modal-texto-2">
                            <b>Status:</b>
                            &nbsp;
                            {StatusPedidoEnum.get(this.props.pedido.status).text}
                        </div>
                    </div>

                    {
                        this.props.pedido.mensagem_pagamento &&                    
                            <div 
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '10px',
                                padding: '15px',
                                background: 'yellow'                        
                              }}
                            >
                                <div className="header-modal-texto-2 color-black">
                                    <b>Motivo:</b>                                
                                    &nbsp;
                                    {this.props.pedido.mensagem_pagamento}
                                </div>                        
                            </div>
                    }
                </ModalTitle>

                <Modal.Body >                                                    
                    <div className="container-overflow">                     
                        {/* <PedidoHistorico
                            historico={this.props.pedido.pedido_historico}
                        /> */}

                        <section className="">
                            <Table
                                head={this.props.head}
                                body={this.props.body}
                                ref={ref => this.table = ref}

                                cadastro={false}                                                                                             
                            />                                                        
                        </section>

                        <div 
                            className="col-md-12                                 
                                        col-sm-12                                               
                                        col-lg-12                                               
                                        col-xs-12"
                            style={{
                                borderTop: '1px solid #e6e4e6',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                marginBottom: '10px'
                            }}
                        >
                            {this.showSummary("Subtotal", moeda(this.props.pedido.totalizador.sub_total))}                        

                            {this.showSummary("Taxa de entrega", this.getTaxaEntrega())}

                            {this.showSummary("Total", moeda(this.props.pedido.totalizador.total))}                        
                        </div>                        
                        
                        {
                            this.props.pedido.endereco_entrega.cep &&                        
                            <div 
                                className="col-md-12                                 
                                            col-sm-12                                               
                                            col-lg-12                                               
                                            col-xs-12"
                                style={{
                                    borderTop: '1px solid #e6e4e6',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    marginBottom: '10px'
                                }}
                            >
                                <div 
                                    className="col-md-12                                 
                                                col-sm-12                                               
                                                col-lg-12                                               
                                                col-xs-12
                                                text-font-16px"
                                    style={{         
                                        paddingLeft: '0px',
                                        paddingRight: '0px',                           
                                        marginTop: '10px'                                    
                                    }}
                                >
                                    <b>Endereço de entrega</b>
                                </div>

                                <div 
                                    className="col-md-12                                 
                                                col-sm-12                                               
                                                col-lg-12                                               
                                                col-xs-12
                                                text-font-14px"
                                    style={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',                                                                        
                                    }}
                                >                                 
                                    {this.props.pedido.endereco_entrega.cep + ' - ' + formataEndereco(this.props.pedido.endereco_entrega.logradouro, this.props.pedido.endereco_entrega.endereco, true) + ', ' + this.props.pedido.endereco_entrega.numero}                            
                                </div> 

                                <div 
                                    className="col-md-12                                 
                                                col-sm-12                                               
                                                col-lg-12                                               
                                                col-xs-12
                                                text-font-14px"
                                    style={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',                                                                        
                                    }}
                                >                                                             
                                    {this.props.pedido.endereco_entrega.bairro + ' - ' + this.props.pedido.endereco_entrega.cidade + ' ' + this.props.pedido.endereco_entrega.uf}
                                </div> 
                            </div>  
                        }                     

                        {
                            this.props.pedido.observacao &&                        
                            <div 
                                className="col-md-12                                 
                                            col-sm-12                                               
                                            col-lg-12                                               
                                            col-xs-12"
                                style={{
                                    borderTop: '1px solid #e6e4e6',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    marginBottom: '10px'
                                }}
                            >
                                <div 
                                    className="col-md-12                                 
                                                col-sm-12                                               
                                                col-lg-12                                               
                                                col-xs-12
                                                text-font-16px"
                                    style={{         
                                        paddingLeft: '0px',
                                        paddingRight: '0px',                           
                                        marginTop: '10px'                                    
                                    }}
                                >
                                    <b>Obervação</b>
                                </div>

                                <div 
                                    className="col-md-12                                 
                                                col-sm-12                                               
                                                col-lg-12                                               
                                                col-xs-12
                                                text-font-14px"
                                    style={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',                                                                        
                                    }}
                                >                                 
                                    <textarea
                                        style={{
                                            height: "100px",
                                            minHeight: "100px",
                                        }}
                                        spellCheck="false"
                                        className="form-control textArea"
                                        value={this.props.pedido.observacao}
                                        disabled
                                    />                                    
                                </div>                             
                            </div>                                               
                        }
                    </div>

                    {
                        (
                          [StatusPedidoEnum.AGUARDANDO_PAGAMENTO.enumName, StatusPedidoEnum.PAGAMENTO_NEGADO.enumName].includes(StatusPedidoEnum.get(this.props.pedido.status).enumName) &&
                          !this.props.pagamentoAprovado
                        ) &&
                            <Button
                                type="button"
                                className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"  
                                onClick={() => this.props.callPagamento(this.props.pedido)}
                            >
                                REALIZAR PAGAMENTO
                            </Button>
                    }
                </Modal.Body>
            </Modal>
        )
    }
}

export default PedidoDetalheModal;
